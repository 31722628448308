import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import t from 'prop-types';

let timerVar;

function Alert({ show, onClose, closeIn, children, color }) {
  const [hiding, setHiding] = useState(false);

  const close = () => {
    setHiding(true);
    setTimeout(() => {
      onClose();
      setHiding(false);
    }, 200);
  };

  const handleCloseClick = () => {
    close();
  };

  useEffect(() => {
    let timer;
    clearInterval(timerVar);

    const setCounter = () => {
      timer = closeIn;
      const timerVar = setInterval(() => {
        if (timer < 1) {
          close();
          clearInterval(timerVar);
          return;
        }
        timer -= 1;
      }, 1000);
    };

    if (closeIn && show) {
      setCounter();
    }

    return clearInterval(timerVar);
  }, [closeIn, show]);

  return (
    <React.Fragment>
      {show && children && (
        <div
          className={cx(
            { 'opacity-0': hiding },
            'absolute top-0 left-0 mt-12 w-full flex content-center justify-center transition-opacity duration-200',
          )}
          role="alert"
        >
          <div
            className={cx(
              `bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative pr-12`,
            )}
            role="alert"
          >
            <span className="block sm:inline">{children}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={handleCloseClick}>
              <svg
                className={cx(`fill-current h-6 w-6 text-${color}-500`)}
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

Alert.propTypes = {
  show: t.bool.isRequired,
  children: t.string.isRequired,
  closeIn: t.number,
  color: t.string,
  onClose: t.func,
};

Alert.defaultProps = {
  color: 'green',
  setShow: () => {},
  closeIn: 0,
};

export default Alert;
